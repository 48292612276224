<template>
    <a-modal
        :visible="visible"
        title="Unggah Master Data RKAP"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true">

        <template #footer>
            <AButton
                @click="handleModalCancel">Batal</AButton>
            <AButton
                key="submit"
                type="primary"
                :loading="state.loading"
                @click="handleSubmitOk">Submit</AButton>
        </template>

        <AForm
            class="myform"
            @submit.prevent="btnUploadFile()"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Unggah Dokumen"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        has-feedback>
                        <AInput
                            v-if="state.fileShow"
                            type="file"
                            required
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @change="onFileChange"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
            
            <a-row class="form-row mb-4">
                <ACol :sm="4"/>
                <a-col :sm="20">
                    <ASpace>
                        <AButton
                            type="primary"
                            html-type="submit"
                            :loading="state.loading">Unggah</AButton>
                        <AButton
                            @click="btnDownloadTemplate()"
                            :loading="state.loadingTemplate">Unduh Template</AButton>
                    </ASpace>
                </a-col>
            </a-row>
        </AForm>

        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <br>

        <MdTable
            :columns="state.columns"
            :data-source="state.data"
            row-key="no"
            :row-selection="rowSelection"
            size="small"
            :loading="state.loading">
            <template #tags="{ text, record }">
                <a-tag :color="text ? 'green' : 'volcano'">
                <template #icon>
                    <template v-if="text"> <CheckCircleOutlined /> Ya </template>
                    <template v-else>
                        <a-popover trigger="click">
                            <template #content>
                            <a-list size="small" :data-source="record.errors">
                                <template #renderItem="{ item }">
                                    <a-list-item>{{ `${item.field} ${item.message}` }}</a-list-item>
                                </template>
                            </a-list>
                            </template>
                            <CloseCircleOutlined /> Tidak
                        </a-popover>
                    </template>
                </template>
                </a-tag>
            </template>
            <template #status="{ record }">
                <a-list size="small" :data-source="record.errors">
                    <template #renderItem="{ item }">
                        <a-list-item>{{ item.message }}</a-list-item>
                    </template>
                </a-list>
            </template>
        </MdTable>

        <AModal
            v-model:visible="state.result.isShow"
            title="Result"
            @ok="state.result.isShow = false">
            <template #footer>
                <AButton key="back" type="primary" @click="handleShowCancel">OK</AButton>
            </template>
            <AAlert type="success" :message="`Sukses: ${state.result.valid}`"></AAlert>
            <AAlert type="error">
                <template #message>
                {{ `Gagal: ${state.result.failed}` }}<a-tree :tree-data="state.result.data" default-expand-all></a-tree> </template
            ></AAlert>
        </AModal>

    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    computed,
    nextTick,
} from 'vue'
import apiClient from '@/services/axios'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'
import moment from 'moment'
import { findIndex } from 'lodash'

export default defineComponent({
    components: {
        CheckCircleOutlined,
        CloseCircleOutlined,
    },
    props: {
        visible: [Boolean],
    },
    emits: ['btnUploadFileConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'Valid?',
                    dataIndex: 'valid',
                    slots: {
                        customRender: 'tags',
                    },
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'kode_distributor',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'nama_distributor',
                },
                {
                    title: 'Nilai RKAP',
                    dataIndex: 'nilai_rkap',
                },
                {
                    title: 'Bulan',
                    dataIndex: 'bulan',
                },
                {
                    title: 'Tahun',
                    dataIndex: 'tahun',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    slots: {
                        customRender: 'status',
                    },
                },
            ],
            data: [],
            loading: false,
            loadingTemplate: false,
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            result: {
                isShow: false,
                data: [],
                valid: 0,
                failed: 0,
            },
            file: null,
            fileShow: true,
            selectedRowKeys: [],
            selectedRows: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
        }

        const onFileChange = (e) => {
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            state.file = files[0]
        }

        const rowSelection = computed(() => {
            return {
                selectedRowKeys: state.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    state.selectedRowKeys = selectedRowKeys
                    state.selectedRows = selectedRows
                },
                getCheckboxProps: record => ({
                    disabled: !record.valid,
                }),
            }
        })

        const btnUploadFile = async () => {
            state.loading = true
            const form_data = new FormData()

            form_data.append('file', state.file)

            apiClient
                .post('/api/rkap/upload', form_data)
                .then(({ data }) => {
                    state.data = data
                })
                .catch(async error => {
                    errorMessage.value = null

                    if (error.response && error.response.status !== 500) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }

                    if (error.response && error.response.status === 500) {
                        const { status, statusText } = error.response
                        const message = error.response.data.message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                    message.error('Gagal mengungah!')
                })
                .finally(() => {
                    state.loading = false
                    state.file = null
                    state.fileShow = false
                    nextTick().then(() => {
                        state.fileShow = true
                    })
                })
        }

        const btnDownloadTemplate = async () => {
            state.loadingTemplate = true

            apiClient
                .get('/sample-upload/rkap_upload.xlsx', {
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    if (response === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `master-data-rkap_${moment().format('DDMMYY_HHmm')}.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loadingTemplate = false
                })
        }

        const handleSubmitOk = async () => {
            state.result.data = []
            errorMessage.value = null
            state.loading = true

            let payload = []
            state.selectedRows.forEach(item => {
                payload.push({
                    ...item,
                    action: "create",
                })
            })

            apiClient
                .post('/api/rkap/upload', { simpan: payload })
                .then(({ data }) => {
                    if (Array.isArray(data) && data.length === 0) {
                        message.warning('Tidak ada data!, pastikan sudah memilih datanya')
                        return
                    }

                    state.result.isShow = true

                    if (data.success) {
                        state.result.valid = data.success.length

                        data.success.forEach(item => {
                            let index = findIndex(state.data, (val) => val.kode_distributor == item.kode_distributor);
                            state.data.splice(index, 1)
                        })
                    }

                    if (data.skipped) {
                        state.result.failed = data.skipped.length
    
                        data.skipped.forEach((value, idx) => {
                            if ((value.valid === false) || (value.errors)) {
                                const branch = {}
                                state.result.data.push(branch)
                                branch.title = 'Baris ' + (idx + 1)
                                branch.key = idx
                                branch.children = []
                                if (value.errors) {
                                    value.errors.forEach((v, i) => {
                                        branch.children.push({
                                            title: `Kolom ${v.field} : ${v.message}`,
                                            key: `${idx}-${i}`,
                                        })
                                    })
                                }
                            }
                        })
                    }
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                    const { status, statusText } = error.response
                        const message = error.response.data.message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loading = false
                    state.selectedRowKeys = []
                    state.selectedRows = []
                })
        }

        const handleShowCancel = () => {
            state.result.isShow = false
        }

        // handle func vue
        onMounted(() => {
        })

        return {
            errorMessage,
            state,
            btnUploadFile,
            handleSubmitOk,
            handleModalCancel,
            onFileChange,
            rowSelection,
            btnDownloadTemplate,
            handleShowCancel,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
